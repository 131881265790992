import {QuoteConstants as Constants} from './Types'
import {handleResponse} from './../../Helpers'
import {Firebase} from './../../Config/firebase'
import 'firebase/database';
import "firebase/auth";
export const database = Firebase.database();
export const auth = Firebase.auth();

export const QuoteActions = {
    get_all,
    add,
};


function get_all(id = null) {
    const reference = database.ref('quotes');    
    if(!id)
    {
        return dispatch => {
            return new Promise((resolve, reject) => {
                reference.orderByChild('created').on("value", function(snapshot) {
                    console.log("Snapshot", snapshot.val())
                    if (snapshot.val()) {
                        let payload = snapshot.val();
                        dispatch(success(payload))
                        resolve(payload)
                    } 
                    else
                    {
                        dispatch(success([]))
                        resolve([])
                    }
                }, function (error) {
                        reject(error)
                        dispatch(failure('error'))                
                });            
            });
        }
    }
    else
    {
        return dispatch => {
            return new Promise((resolve, reject) => {
                reference.orderByChild('companyid').equalTo(id).on("value", function(snapshot) {
                    if (snapshot.val()) {
                        let payload = snapshot.val();
                        dispatch(success(payload))
                        resolve(payload)
                    } 
                    else
                    {
                        dispatch(success([]))
                        resolve([])
                    }
                }, function (error) {
                        reject(error)
                        dispatch(failure('error'))                
                });            
            });
        }
    }
    function success(payload) { return { type: Constants.GET_ALL, payload} }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function add(form_data, bcc= 'awaissahmed@gmail.com'){
    const reference = database.ref('quotes'); 
    return dispatch => {
        return new Promise((resolve, reject) => {
            let push_key = reference.push().getKey();
            reference.child(push_key).set(form_data).then(save => {
                let item_data = {...form_data, id: push_key};
                send_mail(item_data, 'finance@sslmalta.com', bcc)                
            })
            .then((response) =>{
                let item_data2 = {...form_data, id: push_key};
                dispatch(success(item_data2))
                resolve(item_data2)    
            })
            .catch(err => {           
                dispatch(failure())
                reject(err)
            })
        });
    }
    function success(payload) { return { type: Constants.ADD, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }    
}

async function send_mail(quote, to, bcc=null) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify({quote: quote, to: to, bcc: bcc})
    };

    let uri = 'https://us-central1-malta-b890d.cloudfunctions.net/sendMail';
    const response = await fetch(uri, requestOptions);
    const data = await handleResponse(response);
    return data;
}
