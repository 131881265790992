import {AuthConstants} from './Types'
import {Firebase} from './../../Config/firebase'
import 'firebase/database';
import "firebase/auth";
import moment from 'moment'

export const database = Firebase.database();
export const auth = Firebase.auth();

export const AuthActions = {
    login,
    signup,
    reset_password
};


function login(email, password) {
    const reference = database.ref('users');    
    return dispatch => {
        return new Promise((resolve, reject) => {
            auth.signInWithEmailAndPassword(email, password)
                .then((result) => {                
                    reference.child(result.user.uid)
                        .on('value', snapshot => {
                            if (snapshot.val()) {
                                let user = {...snapshot.val(), id: snapshot.key};                                
                                dispatch(success(user))
                                resolve(user)
                            }
                        })   
                
            })
            .catch(error => {
                // context.commit("setNotifications",{message:error.message,type:'error'})
                reject(error)
                dispatch(failure('error'))
                
            });

        });
    }
    function success(payload) { console.log("Payload to dispatch", payload); return { type: AuthConstants.LOGIN, payload } }
    // function success2(payload) { console.log("Payload to dispatch", payload); return { type: AuthConstants.LOGIN, payload } }
    function failure(error) { return { type: AuthConstants.FAILED, error } }
}

function signup(form_data) {
    const reference = database.ref('users');
    let {email, password} = form_data
    let user = { 
        name: form_data.name, 
        email: form_data.email,
        created: moment().format('YYYY-MM-DD'),
        address: form_data.address,
        company_id: null,
        vesselname: form_data.company,
        role: "USER",
        show_prices: "none"
    }
    return dispatch => {
        return new Promise((resolve, reject) => {
            auth.createUserWithEmailAndPassword(email, password).then(result => {
                reference.child(result.user.uid).set(user).then(save => {                    
                    let user_data = {...user, id: result.user.uid};
                    dispatch(success(user_data))
                    resolve(user_data)
                }).catch(err => {                    
                    dispatch(failure())
                    reject(err)
                })
            }).catch(err => {                                
                dispatch(failure())
                reject(err)
            })

        });
    }
    function success(payload) { return { type: AuthConstants.LOGIN, payload } }
    function failure(error) { return { type: AuthConstants.FAILED, error } }
}

function reset_password(email) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            auth.sendPasswordResetEmail(email).then(() => {
                resolve()
                dispatch(failure())
            }).catch(err => {
                reject(err)
                dispatch(failure())
            })
        });
    }
    function failure(error) { return { type: AuthConstants.FAILED, error } }
}