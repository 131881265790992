import React from 'react'

export const isEmpty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}


export const titleCase = (str) =>{
    var sentence = str.toLowerCase().split(" ");
    for(var i = 0; i< sentence.length; i++){
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(" ");
}

export const validateEmailString = (str) => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(str))
    {
        return true
    }
    return false
}
export const SmartLoader = () => (
    <svg width="2em" height="0.7em" fill="currentColor" viewBox="0 0 120 30">
        <circle cx="15" cy="15" r="15">
            <animate attributeName="r" from="15" to="15"
                    begin="0s" dur="0.8s"
                    values="15;9;15" calcMode="linear"
                    repeatCount="indefinite" />
            <animate attributeName="fillOpacity" from="1" to="1"
                    begin="0s" dur="0.8s"
                    values="1;.5;1" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
        <circle cx="60" cy="15" r="9" fillOpacity="0.3">
            <animate attributeName="r" from="9" to="9"
                    begin="0s" dur="0.8s"
                    values="9;15;9" calcMode="linear"
                    repeatCount="indefinite" />
            <animate attributeName="fillOpacity" from="0.5" to="0.5"
                    begin="0s" dur="0.8s"
                    values=".5;1;.5" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
        <circle cx="105" cy="15" r="15">
            <animate attributeName="r" from="15" to="15"
                    begin="0s" dur="0.8s"
                    values="15;9;15" calcMode="linear"
                    repeatCount="indefinite" />
            <animate attributeName="fillOpacity" from="1" to="1"
                    begin="0s" dur="0.8s"
                    values="1;.5;1" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
    </svg>
)


export const  imageToDataUri = (img, width, height) => {

    // create an off-screen canvas
    var canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

    // set its dimension to target size
    canvas.width = width;
    canvas.height = height;

    // draw source image into the off-screen canvas:
    ctx.drawImage(img, 0, 0, width, height);

    // encode image to data-uri with base64 version of compressed image
    return canvas.toDataURL();
}

export const handleResponse = (response) => {
    return response.text()
        .then((my_data) => {

            if(response.status === 404)
            {
                return Promise.reject('Error 404: Requested uri does not exist.')
            }

            if(response.status === 401)
            {
                return Promise.reject('Error 401: You are not authorized.')
            }

            if(response.status === 403)
            {
                localStorage.removeItem('user');
                window.location = '/login';
                return Promise.reject('Error 403: Forbidden.')
            }

            if(response.status === 400)
            {
                const data = my_data && JSON.parse(my_data);
                return Promise.reject(data)
            }

            const data = my_data && JSON.parse(my_data);
            if (!response.ok) {
                const error = (data && data.error) || response.statusText;
                return Promise.reject(error);
            }

            return data
        })
        .catch(error => {
            return Promise.reject(error);
        });
}