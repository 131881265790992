import {ItemConstants as Constants} from './Types'
import {Firebase} from './../../Config/firebase'
import 'firebase/database';
import "firebase/auth";
export const database = Firebase.database();
export const auth = Firebase.auth();

export const ItemActions = {
    get_all,
    add,
};


function get_all() {
    const reference = database.ref('inventory');    
    return dispatch => {
        return new Promise((resolve, reject) => {
            reference.on("value", function(snapshot) {
                if (snapshot.val()) {
                    let payload = snapshot.val();
                    dispatch(success(payload))
                    resolve(payload)
                } 
                else
                {
                    dispatch(success([]))
                    resolve([])
                }
            }, function (error) {
                    reject(error)
                    dispatch(failure('error'))                
            });             
        });
    }
    function success(payload) { return { type: Constants.GET_ALL, payload} }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function add(form_data, next_id, update = false){
    const reference = database.ref('inventory');
    
    form_data['available'] = form_data.available === 1 ? true : false
    
    return dispatch => {
        return new Promise((resolve, reject) => {
            // let push_key = reference.push().getKey();
            reference.child(next_id).set(form_data).then(save => {
                let item_data = {...form_data, id: next_id};
                if(update)
                {
                    dispatch(success_update(item_data))    
                }
                else
                {
                    dispatch(success(item_data))
                }
                resolve(item_data)
            }).catch(err => {                    
                dispatch(failure())
                reject(err)
            })
        });
    }
    function success(payload) { return { type: Constants.ADD, payload } }
    function success_update(payload) { return { type: Constants.UPDATE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }    
}