import {CompanyConstants as Constants} from './../Actions/Types'

//let user = localStorage.getItem('user');

const initialState = {
    companies: [],
    users: []
};

export function CompanyReducer(state = initialState, action) {
    switch (action.type) {        
        case Constants.GET_ALL:            
            let companies_array=[]
            for(let key in action.payload)
            {
                action.payload[key].id=key
                companies_array.push(action.payload[key])
            }
            return {
                ...state,
                companies: companies_array,
            }  
            
        case Constants.GET_USERS:            
            let users_array=[]
            for(let key in action.payload)
            {
                action.payload[key].id=key
                users_array.push(action.payload[key])
            }
            return {
                ...state,
                users: users_array,
            }  

        case Constants.UPDATE_USER:
            let users = state.users
            let index = -1
            index = users.findIndex(user => user.id === action.payload.id);
            if(index > -1)
            {
                users[index] = action.payload;
                return {
                    ...state,
                    users: users
                }
            }
            return {
                ...state,
            }

        case Constants.UPDATE_COMPANY:
            let all_companies = state.companies
            let comp_index = -1
            comp_index = all_companies.findIndex(comp => comp.id === action.payload.id);
            if(comp_index > -1)
            {
                all_companies[comp_index] = action.payload;
                return {
                    ...state,
                    companies: all_companies
                }
            }
            return {
                ...state,
            }
            
        case Constants.ADD:
            return {
                ...state,
                companies: [...state.companies, action.payload]
            }

        case Constants.ADD_USER:
            return {
                ...state,
                users: [...state.users, action.payload]
            }
            
        case Constants.FAILED:
            
            return {
                ...state,
            } 
       
        default:
            return state
            

    }
}