import React, {useState, useRef } from 'react'
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { AppStore, persistor } from './Store/AppStore'
import {Routes} from './Routes'
import './less/App.less'
// import { useDispatch, useSelector } from "react-redux";

export const App = (props) => {

    return (
        <Provider store={AppStore}>
            <PersistGate loading={null} persistor={persistor}>
                <Routes />
            </PersistGate>
        </Provider>
    )
};
