import {ItemConstants as Constants} from './../Actions/Types'

//let user = localStorage.getItem('user');

const initialState = {
    items: []
};

export function ItemReducer(state = initialState, action) {
    switch (action.type) {        
        case Constants.GET_ALL:            
            let items_array=[]
            for(let key in action.payload)
            {
                action.payload[key].id=key
                items_array.push(action.payload[key])
            }
            return {
                ...state,
                items: items_array,
            }   
            
        case Constants.ADD:
            return {
                ...state,
                // items: [...state.items, action.payload]
            }
        
        case Constants.UPDATE:
            let all_items = state.items
            let index = all_items.findIndex(item => item.id === action.payload.id)
            if(index > -1)
            {
                all_items[index] = action.payload
            }
            
            return {
                ...state,
                items: all_items
            }
        case Constants.FAILED:
            
            return {
                ...state,
            } 
       
        default:
            return state
            

    }
}