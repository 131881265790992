import  firebase from 'firebase/app';
const firebaseConfig = {
    // apiKey: "AIzaSyB1jnv7MFLbN2SuJUKWOD9VPedKUX0kth4",
    // authDomain: "malta-d6d28.firebaseapp.com",
    // databaseURL: "https://malta-d6d28-default-rtdb.firebaseio.com",
    // projectId: "malta-d6d28",
    // storageBucket: "malta-d6d28.appspot.com",
    // messagingSenderId: "283806746534",
    // appId: "1:283806746534:web:84da1ff8ff748fbc713167",
    // measurementId: "G-NYN8XNY2NG"
    apiKey: "AIzaSyCzCzp2BG2AnWWH9544o6UWWUihC4N1AZo",
    authDomain: "malta-b890d.firebaseapp.com",
    databaseURL: "https://malta-b890d.firebaseio.com",
    projectId: "malta-b890d",
    storageBucket: "malta-b890d.appspot.com",
    messagingSenderId: "1000422433794",
    appId: "1:1000422433794:web:2094b55763200e61d6b31b",
    measurementId: "G-JKVFST5NP9"
  };

export const Firebase = firebase.initializeApp(firebaseConfig);