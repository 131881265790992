import { combineReducers } from 'redux'
import { AuthReducer } from './AuthReducer'
import { CategoryReducer } from './CategoryReducer';
import { CompanyReducer } from './CompanyReducer';
import { UserReducer } from './UserReducer';
import { ItemReducer } from './ItemReducer'
import { CartReducer } from './CartReducer'
import { QuoteReducer } from './QuoteReducer'

export default combineReducers({
    auth: AuthReducer,
    companies: CompanyReducer,
    users: UserReducer,
    categories: CategoryReducer,
    inventory: ItemReducer,
    cart: CartReducer,
    quotes: QuoteReducer
});