import React from 'react'
import {Link} from 'react-router-dom'
import {DashboardFilled, UserOutlined, BellOutlined,  TeamOutlined, ReconciliationOutlined, ApartmentOutlined, BarcodeOutlined, FileDoneOutlined} from '@ant-design/icons'
import { useSelector, useDispatch } from "react-redux";
import {Avatar, Popover} from 'antd'
import {isEmpty} from './../Helpers'
import {rootReducer} from './../Store/AppStore'

const AdminLayout = (props) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    let {user} =  auth    
    
    console.log("Loaded layout again")
    if(isEmpty(user))
    {
        props.history.push('/login');
    }        

    function handleLogout(e)
    {
        e.preventDefault();
        dispatch(() => rootReducer({}, {type: "USER_LOGOUT"}))   
    }

    const popover_content = (
        <div className="header-user-menu">
            <div className="user-meta">
                <h3>{user.name}</h3>
                <p>{user.email}</p>          
            </div>
            <div className="user-links">
                <Link to="#" onClick={handleLogout}> Signout</Link>
                {/* <Link to="/"> Profile</Link> */}
            </div>
        </div>
      );

    return(
            <div className="dashboard-wrapper">
                <div className="sidebar">
                    <div className="sidebar-inner">
                        <div className="logo">SSL Malta</div>
                        <ul>
                            <li className={props.location.pathname === '/' ? 'active' : ''}>
                                <Link to="/"><DashboardFilled /> Dashboard </Link>
                            </li>
                            <li className={props.location.pathname === '/companies' ? 'active' : ''}>
                                <Link to="/companies"><ApartmentOutlined /> Companies</Link>
                            </li>
                            <li className={props.location.pathname === '/users' ? 'active' : ''}>
                                <Link to="/users"><TeamOutlined /> Users </Link>
                            </li>
                            <li className={props.location.pathname === '/categories' ? 'active' : ''}>
                                <Link to="/categories"><ReconciliationOutlined /> Categories </Link>
                            </li>
                            <li className={props.location.pathname === '/items' ? 'active' : ''}>
                                <Link to="/items"><BarcodeOutlined /> Inventory Items </Link>
                            </li>
                            <li className={props.location.pathname === '/quotes' ? 'active' : ''}>
                                <Link to="/quotes"><FileDoneOutlined /> Quotes </Link>
                            </li>
                        </ul>
                    </div>   
                </div>
                <div className="dashboard-contents">
                    <div className="header">
                        <div className="header-inner">                            
                            <BellOutlined className="bell-icon" />
                            <Popover overlayClassName="user-menu" placement="bottomRight" content={popover_content} trigger="click">    
                                <Avatar size="small" icon={<UserOutlined />} />
                            </Popover>
                            

                        </div>
                    </div>
                    <div className="dashboard-contents-inner">
                        {props.children}
                    </div>
                </div>
            </div>
        )
    
}
export default React.memo(AdminLayout)