import {UserConstants as Constants} from './../Actions/Types'

//let user = localStorage.getItem('user');

const initialState = {
    users: [],
};

export function UserReducer(state = initialState, action) {
    switch (action.type) {        
        case Constants.GET_ALL:            
            let users_array=[]
            for(let key in action.payload)
            {
                action.payload[key].id=key
                users_array.push(action.payload[key])
            }
            return {
                ...state,
                users: users_array,
            }   
            
        case Constants.ADD:
            return {
                ...state,
                users: [...state.users, action.payload]
            }

        case Constants.UPDATE:
            let users = state.users
            let index = -1
            index = users.findIndex(user => user.id === action.payload.id);
            if(index > -1)
            {
                users[index] = action.payload;
                return {
                    ...state,
                    users: users
                }
            }
            return {
                ...state,
            }
            
        case Constants.FAILED:
            
            return {
                ...state,
            } 
       
        default:
            return state
            

    }
}