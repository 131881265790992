import React, {useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import {Link} from 'react-router-dom'
import {CartActions} from './../../Store/Actions'
import {Row, Col, InputNumber, Alert, Typography } from 'antd';
import {CloseCircleFilled, EditOutlined} from '@ant-design/icons'
import {isEmpty} from './../../Helpers'
const {Paragraph} = Typography


const  CartItems = (props) => {

    const [loading, setLoading] = useState(false);
    const {items} = useSelector(state => state.cart);     
    const {user} = useSelector(state => state.auth)   
    const dispatch = useDispatch();

    function removeItem(id)
    {
        dispatch(CartActions.remove(id))
    }

    function updateQty(item, qty, index)
    {
        setLoading(item.id)
        if(qty > 0)
        {
            item['quantity'] = parseInt(qty)
            dispatch(CartActions.update(item, index))
                .then((response) => {
                    setLoading(null)
                })
        }
        else
        {
            dispatch(CartActions.remove(item.id))
            .then((response) => {
                setLoading(null)
            })
        }
    }

    function showDiscounted(price)
    {
        let discount = 0;
        if(user.company && user.company.discount && user.company.discount > 0)
        {
            discount = parseFloat(user.company.discount)
        }
        
        let total_discount = parseFloat(( price / 100 ) * discount);
        return parseFloat(price - total_discount)
    }
    
    function setComments(item, comments, index)
    {
        setLoading(item.id)        
        item['comments'] = comments
        dispatch(CartActions.update(item, index))
            .then((response) => {
                setLoading(null)
            })
        
    }

    return (
        <>
            <div className="cart-items">
                {
                    (items && items.length > 0) ?
                    <>
                    {
                        items.map((item, i) =>
                        <div className="cart-item" key={i}>
                            <Row gutter={24}>
                                <Col span={1}>
                                    <Link to="#" className="remove-link" onClick={() => removeItem(item.id)}><CloseCircleFilled /></Link>
                                </Col>
                                <Col span={12}>
                                    <h4>{item.name}</h4>
                                    <p>{item.sku_vcode}</p>
                                    {
                                        isEmpty(user) &&
                                        <p><strong>€{item.saleprice.toFixed(2)}</strong></p>
                                    }
                                    {
                                        !isEmpty(user) &&  user.show_prices !== 'none' &&
                                        <>
                                            {
                                                user.show_prices === 'sale' &&
                                                <p><strong>€{showDiscounted(item.saleprice).toFixed(2)}</strong></p>
                                            }
                                            {
                                                user.show_prices === 'discount' &&
                                                <p><strong>€{item.discountprice > 0 ? showDiscounted(item.discountprice).toFixed(2) : showDiscounted(item.saleprice).toFixed(2)}</strong></p>
                                            }
                                        </>
                                    }
                                    
                                </Col>
                                <Col span={!isEmpty(user) && user.show_prices === 'none' ? 10 : 6} className="text-right">
                                    <InputNumber disabled={loading === item.id} style={{ textAlign: 'center', width: '80px' }}  onChange={(val) => updateQty(item, val, i)} value={parseInt(item.quantity)} />
                                </Col>
                                {      
                                    isEmpty(user) &&
                                    <Col span={4} className="text-right">
                                        €{
                                            (parseFloat(item.saleprice) * parseInt(item.quantity)).toFixed(2)
                                        }
                                    </Col>
                                }
                                {
                                    !isEmpty(user) && user.show_prices !== 'none' &&
                                    <Col span={4} className="text-right">
                                    €                                    
                                    {      
                                        !isEmpty(user) &&  user.show_prices === 'sale' &&
                                        showDiscounted((parseFloat(item.saleprice) * parseInt(item.quantity))).toFixed(2)
                                    }
                                    {
                                        !isEmpty(user) &&  (user.show_prices === 'discount') && 
                                            <>
                                            {
                                            (item.discountprice > 0) ? 
                                                showDiscounted((parseFloat(item.discountprice) * parseInt(item.quantity))).toFixed(2)
                                                :
                                                showDiscounted((parseFloat(item.saleprice) * parseInt(item.quantity))).toFixed(2)
                                            }
                                            </>
                                    }
                                    </Col>
                                }
                                
                            </Row>
                            <Row gutter={12}>
                                <Col span={1}></Col>
                                <Col span={22}>
                                    <div className="item-comments">
                                        <Paragraph
                                            editable={{
                                                tooltip: item.comments ? 'Edit' : 'Add Comments',
                                                icon: item.comments ? <EditOutlined /> : <span>Add Comments</span>,
                                                onChange: (val) =>setComments(item, val, i),
                                                maxLength: 255,
                                                autoSize: { maxRows: 2, minRows: 1 },
                                            }}
                                        >
                                            {item.comments}
                                        </Paragraph>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        )
                    }
                    </>
                    :
                    <>
                        <Alert type="warning" message="Your shopping cart is empty, please add items to request a quotation" description="" />
                    </>                    
                }
            </div>
        </>
    );
};

export default CartItems