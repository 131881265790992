import React, {useState } from 'react'
import {Link} from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import {isEmpty} from './../Helpers'
import {QuoteActions, CartActions} from './../Store/Actions'
import {Tabs, Avatar, Popover, Row, Col, Input, Form, notification, Button, Modal, Badge, DatePicker} from 'antd'
import {UserOutlined} from '@ant-design/icons'
import CartItems from './../Views/User/CartItems'
import CustomItems from './../Views/User/CustomItems'
import {rootReducer} from './../Store/AppStore'
import moment from 'moment'

const UserLayout = (props) => {

    const auth = useSelector(state => state.auth);
    const [submitting, setSubmitting] = useState(false)
    const {items, custom} = useSelector(state => state.cart);        
    const [state, setState] = useState({})
    const [visible, setVisible] = useState(false)
    let {user} =  auth   
    const dispatch = useDispatch();

    // if(isEmpty(user))
    // {
    //     props.history.push('/login');
    // }

    function handleLogout(e)
    {
        e.preventDefault();
        dispatch(() => rootReducer({}, {type: "USER_LOGOUT"}))   
    }

    function showDiscounted(price)
    {
        let discount = 0;
        if(user?.company && user?.company?.discount && user?.company?.discount > 0)
        {
            discount = user.company.discount
        }
        
        let total_discount = parseFloat(( price / 100 ) * discount);
        return parseFloat(price - total_discount)
    }
    

    function getCartTotal(type = 'saleprice')
    {
        console.log("User", user)
        console.log("Cart Total Type", type)
        let total = 0;
        if(items && items.length > 0)
        {
            // total +=quote.items.reduce((a, b) => ({x: parseInt(a.quantity)  + parseInt(b.quantity)}));

            total += items.reduce((total, item) => {
                let price_type = type === 'discountprice' && item.discountprice > 0 ? 'discountprice' : 'saleprice'
                return total + (parseInt(item.quantity) * item[price_type])
            }, 0)
        }

        return showDiscounted(total).toFixed(2)        
    }

    function finalizeQuote()
    {
        if(state.delivery_date && state.location)
        {
            setSubmitting(true)
            let quote = {
                eta: moment(state.delivery_date).format('YYYY-MM-DD')+"",
                loadingport: state.location,
                notes: state.notes ? state.notes : null,
                companyid: user?.company_id || null,
                user: user,
                items: items,
                custom: custom,
                created: moment().format('YYYY-MM-DD hh:mm:ss')+""
            }

            dispatch(QuoteActions.add(quote))
                .then((response) => {                    
                    dispatch(CartActions.empty())
                })
                .then(() => {
                    setSubmitting(false);
                    setVisible(false)
                    notification.success({
                        message: 'Request Submitted',
                        description: "Your request has been submitted successfully",
                        duration: 5,
                        className: 'notification-success'
                    }); 
                })
                .catch((error) => {
                    setSubmitting(false);
                    setVisible(false)
                    console.log('Error', error);
                })
                
        }
        else
        {
            notification.error({
                message: 'Quote Error',
                description: "Please fill in the quotation form",
                duration: 5,
                className: 'notification-error'
            });  
        }
    }

    function handleChange(name, value)
    {
        let data = {...state}
        data[name] = value
        setState(data)
    }

    function requestQuote()
    {
        if(!isEmpty(user))
            setVisible(true)
        else
            props.history.push('/login')

    }

    const popover_content = (
        <div className="header-user-menu">
            <div className="user-meta">
                <h3>{user?.name}</h3>
                <p>{user?.email}</p>          
            </div>
            <div className="user-links">
                <Link to="#" onClick={handleLogout}> Signout</Link>
                {/* <Link to="/"> Profile</Link> */}
            </div>
        </div>
    );

    // if(user)
    // {
        return (
            <>
                <div className="dashboard-wrapper">                
                    <div className="user-dashboard-contents">
                        <div className="user-header">
                            <div className="logo">SSL Malta</div>
                        </div>
                        <div className="user-contents">
                            {props.children}               
                        </div>     
                    </div>
                    <div className="sidecart">
                        <div className="sidecart-header">
                            <Row gutter={12}>
                                <Col span={16}>
                                    <h2>Your Cart</h2>                        
                                </Col>
                                <Col span={8} className="text-right">
                                    {
                                        !isEmpty(user) ?
                                        <Popover overlayClassName="user-menu" placement="bottomRight" content={popover_content} trigger="click">    
                                            <Avatar size="small" icon={<UserOutlined />} />
                                        </Popover>
                                        :
                                        <Link to="/login">
                                            <Avatar size="small" icon={<UserOutlined />} />
                                        </Link>
                                    }
                                    
                                </Col>
                            </Row>
                        </div>
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab={<>Items <Badge size="small" count={items.length} /></> } key="1">
                                <CartItems />                            
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={<>Custom Items <Badge size="small" count={custom.length} /></> } key="2">
                                <CustomItems />
                            </Tabs.TabPane>
                        </Tabs>
                        
                        <div className="sidecart-Footer">
                            <div className="footer-inner">
                                <Row gutter={12} align="middle">
                                    <Col span={12}>
                                        <strong>
                                            {
                                            !isEmpty(user) && user?.show_prices !== 'none' &&
                                            'Total: €' +getCartTotal(user.show_prices === 'discount' ? 'discountprice' : 'saleprice')
                                            }
                                        </strong>                                    
                                    </Col>
                                    <Col span={12} className="text-right">
                                        <Button type="primary" disabled={items.length === 0 && custom.length === 0} onClick={requestQuote}>Request Quote</Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>   
                    </div>
                </div>
                <Modal 
                    title="Request Quotation" 
                    okText={'Confirm'} 
                    visible={visible} 
                    onCancel={() => setVisible(false)}
                    onOk={finalizeQuote}
                    confirmLoading={submitting}
                    >
                    <p>You are about to request a qutation for {items.length} products and {custom.length} custom items.</p>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={<strong>Delivery Location + Address</strong>}
                                >
                                <Input onChange={(e) => handleChange('location', e.target.value)} value={state.location} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={<strong>Delivery Needed By</strong>}
                                >
                                <DatePicker format='DD-MMMM-YYYY' onChange={(date) => handleChange('delivery_date', date)} value={state.delivery_date} style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                >
                                <strong style={{ display: 'block' }}>Notes:</strong>
                                <Input.TextArea onChange={(e) => handleChange('notes', e.target.value)} value={state.notes} style={{ width: '100%', display: 'block' }} rows={3} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Modal>
            </>
        );
    // }
    // return (<></>)
};

export default React.memo(UserLayout)