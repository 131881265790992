import {CartConstants as Constants} from './Types'
import {Firebase} from './../../Config/firebase'
import 'firebase/database';
import "firebase/auth";
export const database = Firebase.database();
export const auth = Firebase.auth();

export const CartActions = {
    add,
    update,
    remove,
    add_custom,
    update_custom,
    remove_custom,
    empty
};


function add(item, qty)
{
    return dispatch => {
        return new Promise((resolve, reject) => {            
            let aitem = {item, qty}
            dispatch(success(aitem))
            resolve(aitem)
        });
    }
    function success(payload) { return { type: Constants.ADD, payload } }
}

function add_custom(item)
{
    return dispatch => {
        return new Promise((resolve, reject) => {            
            dispatch(success(item))
            resolve(item)
        });
    }
    function success(payload) { return { type: Constants.ADD_CUSTOM, payload } }
}

function update(item, index)
{
    return dispatch => {
        return new Promise((resolve, reject) => {            
            let aitem = {item, index}
            dispatch(success(aitem))
            resolve(aitem)
        });
    }
    function success(payload) { return { type: Constants.UPDATE, payload } }
}

function update_custom(item, index)
{
    return dispatch => {
        return new Promise((resolve, reject) => {            
            let aitem = {item, index}
            dispatch(success(aitem))
            resolve(aitem)
        });
    }
    function success(payload) { return { type: Constants.UPDATE_CUSTOM, payload } }
}


function remove(id)
{
    return dispatch => {
        return new Promise((resolve, reject) => {            
            dispatch(success(id))
            resolve(id)
        });
    }
    function success(payload) { return { type: Constants.REMOVE, payload } }
}

function remove_custom(id)
{
    return dispatch => {
        return new Promise((resolve, reject) => {            
            dispatch(success(id))
            resolve(id)
        });
    }
    function success(payload) { return { type: Constants.REMOVE_CUSTOM, payload } }
}

function empty()
{
    return dispatch => {
        return new Promise((resolve, reject) => {            
            dispatch(success())
            resolve()
        });
    }
    function success() { return { type: Constants.EMPTY_CART } }
}
