import React, {Component} from 'react'

export default class AuthLayout extends Component{
    constructor(props)
    {
        super(props)
        this.state = {
            loading: false
        };
    }

    render()
    {
        if(this.state.loading)
        {
            return(
                <div>Loading...</div>
            )
        }
        return(
            <div className="auth-wrapper">
                <div className="auth-left-column">
                    <div className="auth-text">
                        <h2>Shipserv Ltd</h2>
                        <p>
                            NO.25, TA MAGGI INDUSTRIAL PARK<br />
                            TRIQ SAN ANNARD<br />
                            XGHAJRA, ZABBAR                            
                        </p>
                        <p>finance@sslmalta.com</p>
                    </div>
                </div>
                <div className="auth-form">
                    <div className="auth-form-inner">
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}