import {AuthConstants} from './../Actions/Types'

//let user = localStorage.getItem('user');

const initialState = {
    user: null,
};

// const initialState = token ? { loggedIn: true, token: token  } : { loggingIn: false };

export function AuthReducer(state = initialState, action) {
    switch (action.type) {
        
        case AuthConstants.LOGIN:
            console.log('action payload in reducer', action)
            return {
                ...state,
                user: action.payload,
            }   
            
        case AuthConstants.AUTH:
            return {
                ...state,
                user: action.payload
            } 
            
        case AuthConstants.UPDATE:
            return {
                ...state,
                user: action.payload
            } 

        case AuthConstants.FAILED:
            console.log("Action.Payload", action.payload)
            return {
                ...state,
                user: null
            } 

        default:
            return state
            

    }
}