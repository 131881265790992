import {CategoryConstants as Constants} from './Types'
import {Firebase} from './../../Config/firebase'
import 'firebase/database';
import "firebase/auth";
export const database = Firebase.database();
export const auth = Firebase.auth();

export const CategoryActions = {
    get_all,
    add,
    remove,
};


function get_all() {
    const reference = database.ref('categories');    
    return dispatch => {
        return new Promise((resolve, reject) => {
            database.ref('subcategories').on('value', subcats => {
                let subcategories = subcats.val();            
                reference.once('value')
                    .then((snapshot) => {
                        if (snapshot.val()) {
                            let payload = {};
                            
                            payload.categories = snapshot.val();
                            payload.subcategories = subcategories
                            dispatch(success(payload))
                            resolve(payload)
                        } 
                        else
                        {
                            dispatch(success([]))
                            resolve([])
                        }                   
                    })
                    .catch(error => {
                        reject(error)
                        dispatch(failure('error'))                
                    });
            })

        });
    }
    function success(payload) { return { type: Constants.GET_ALL, payload} }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function add(form_data){
    
    let {name, available, categoryid} = form_data
    const reference = categoryid ? database.ref('subcategories') : database.ref('categories');
    let category = { 
        name: name,
        available: available === 1 ? true : false,
    }
    if(categoryid)
    {
        category.categoryid = categoryid
    }

    return dispatch => {
        return new Promise((resolve, reject) => {
            let push_key = reference.push().getKey();
            reference.child(push_key).set(category).then(save => {
                let category_data = {...category, id: push_key};
                dispatch(success(category_data))
                resolve(category_data)
            }).catch(err => {                    
                dispatch(failure())
                reject(err)
            })
        });
    }
    function success(payload) { return { type: Constants.ADD, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }    
}

function remove(id){
    
    console.log("ID", id)
    const reference = database.ref('categories');    
    const subreference = database.ref('subcategories');    
    return dispatch => {
        return new Promise((resolve, reject) => {
            console.log("Remove ID")
            reference.child(id).remove();
            subreference.orderByChild('categoryid').equalTo(id).once('value')
                .then((snapshot) => {
                    snapshot.forEach(function(child) {
                          child.ref.remove();
                      });               
                      dispatch(success(id))
                      resolve(id)
                })                
            
        });
    }
    function success(payload) { return { type: Constants.REMOVE, payload } }
}