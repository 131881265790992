import {CategoryConstants as Constants} from './../Actions/Types'

//let user = localStorage.getItem('user');

const initialState = {
    categories: [],
    subcategories: []
};

export function CategoryReducer(state = initialState, action) {
    switch (action.type) {        
        case Constants.GET_ALL:            
            let categories_array=[]
            let subcategories_array=[]
            for(let key in action.payload.categories)
            {
                action.payload.categories[key].id=key
                categories_array.push(action.payload.categories[key])
            }
            for(let key in action.payload.subcategories)
            {
                action.payload.subcategories[key].id=key
                subcategories_array.push(action.payload.subcategories[key])
            }
            return {
                ...state,
                categories: categories_array,
                subcategories: subcategories_array,
            }   
            
        case Constants.ADD:
            if(!action.payload.categoryid)
            {
                return {
                    ...state,
                    categories: [...state.categories, action.payload]
                }
            }
            else
            {
                return {
                    ...state,
                    subcategories: [...state.subcategories, action.payload]
                }
            }
        
        case Constants.REMOVE:            
            console.log("Reducer", action.payload)
            return {
                ...state,
                categories: state.categories.filter((category) => category.id !== action.payload)
            } 

        case Constants.FAILED:
            
            return {
                ...state,
            } 
       
        default:
            return state
            

    }
}