import {CartConstants as Constants} from './../Actions/Types'

//let user = localStorage.getItem('user');

const initialState = {
    items: [],
    custom: []
};

export function CartReducer(state = initialState, action) {
    switch (action.type) {        
        
        case Constants.ADD:
            let items = [...state.items]
            let old_index = items.findIndex(item => item.id === action.payload.item.id)
            
            if(old_index > -1)
            {
                let item = items[old_index]
                item['quantity'] = parseInt(item['quantity']) + parseInt(action.payload.qty)
                console.log("Item", item)
                items[old_index] = item
                
                return{
                    ...state,
                    items: items
                }
            }
            else
            {
                let item = action.payload.item
                item['quantity'] = action.payload.qty
                return {
                    
                    ...state,
                    items: [...state.items, item]
                }
            }

        case Constants.REMOVE:
            return {
                ...state,
                items: [
                    ...state.items.filter((product) => product.id !== action.payload)
                ]
            }

        case Constants.UPDATE:
            let all_items = state.items
            all_items[action.payload.index] = action.payload.item
            return {
                ...state,
                items: all_items
            }
        
        case Constants.ADD_CUSTOM:
            return{
                ...state,
                custom: [...state.custom, action.payload]
            }

        case Constants.UPDATE_CUSTOM:
            let all_customs = state.custom
            all_customs[action.payload.index] = action.payload.item
            return {
                ...state,
                custom: all_customs
            }
            
        case Constants.REMOVE_CUSTOM:
            return {
                ...state,
                custom: [
                    ...state.custom.filter((product, index) => index !== action.payload)
                ]
            }
        
        case Constants.EMPTY_CART:
        
            return {
                items: [],
                custom: []
            } 
                
        case Constants.FAILED:
            
            return {
                ...state,
            } 
       
        default:
            return state
            

    }
}