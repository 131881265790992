import {UserConstants as Constants} from './Types'
import {Firebase} from './../../Config/firebase'
import 'firebase/database';
import "firebase/auth";
import moment from 'moment'
export const database = Firebase.database();
export const auth = Firebase.auth();

export const UserActions = {
    get_all,
    add,
    update,
};


function get_all() {
    const reference = database.ref('users');    
    return dispatch => {
        return new Promise((resolve, reject) => {
            reference.orderByChild('role').equalTo("USER").on("value", function(snapshot) {
                console.log("Snapshot", snapshot.val())
                if (snapshot.val()) {
                    let payload = snapshot.val();
                    dispatch(success(payload))
                    resolve(payload)
                } 
                else
                {
                    dispatch(success([]))
                    resolve([])
                }
            }, function (error) {
                    reject(error)
                    dispatch(failure('error'))                
            });   
        });
    }
    function success(payload) { return { type: Constants.GET_ALL, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function add(form_data){
    const reference = database.ref('users');
    let {email, password} = form_data
    let user = { 
        name: form_data.name, 
        email: form_data.email,
        created: moment().format('YYYY-MM-DD'),
        address: form_data.address,
        company_id: null,
        vesselname: form_data.company,
        role: "USER",
        show_prices: "none"
    }
    return dispatch => {
        return new Promise((resolve, reject) => {
            auth.createUserWithEmailAndPassword(email, password).then(result => {
                reference.child(result.user.uid).set(user).then(save => {                    
                    let user_data = {...user, id: result.user.uid};
                    dispatch(success(user_data))
                    resolve(user_data)
                }).catch(err => {                    
                    dispatch(failure())
                    reject(err)
                })
            }).catch(err => {                                
                dispatch(failure())
                reject(err)
            })

        });
    }
    function success(payload) { return { type: Constants.ADD, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }    
}


function update(user){
    let new_user = {
        name: user.name, 
        email: user.email,
        created: user.created,
        address: user.address,
        company_id: user.company_id ? user.company_id: null,
        vesselname: user.vesselname,
        role: user.role,
        show_prices: user.show_prices
    }
    const reference = database.ref('users');    
    return dispatch => {
        return new Promise((resolve, reject) => {            
            reference.child(user.id).set(new_user).then(save => {                
                dispatch(success(user))
                resolve(user)
            }).catch(err => {                    
                dispatch(failure())
                reject(err)
            })   
        })
    }
    function success(payload) { return { type: Constants.UPDATE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }    
}