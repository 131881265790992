import React, {useState, useRef, Suspense, Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import {useSelector } from "react-redux";
import {isEmpty} from './../Helpers'
import { Spinner, Page404, ScrollToTop } from './../Components'
import AuthLayout from './../Layouts/AuthLayout'
import AdminLayout from './../Layouts/AdminLayout'
import CustomerLayout from './../Layouts/CustomerLayout'
import UserLayout from './../Layouts/UserLayout'
import RouteLayout from './RouteLayout'
// import LoginLayout from './../Layouts/LoginLayout'

const Home = React.lazy(() => import(/*webpackChunkName: "home" */ './../Views/Home'))
const Login = React.lazy(() => import(/*webpackChunkName: "home" */ './../Views/Auth/Login'))
const Signup = React.lazy(() => import(/*webpackChunkName: "home" */ './../Views/Auth/Signup'))
const ResetPassword = React.lazy(() => import(/*webpackChunkName: "home" */ './../Views/Auth/ResetPassword'))

const UserDashboard = React.lazy(() => import(/*webpackChunkName: "user" */ './../Views/User/UserDashboard'))
const AdminDashboard = React.lazy(() => import(/*webpackChunkName: "admin" */ './../Views/Admin/AdminDashboard'))
const CustomerDashboard = React.lazy(() => import(/*webpackChunkName: "customer" */ './../Views/Customer/CustomerDashboard'))


const Companies = React.lazy(() => import(/*webpackChunkName: "admin" */ './../Views/Admin/Companies'))
const Users = React.lazy(() => import(/*webpackChunkName: "admin" */ './../Views/Admin/Users'))
const Categories = React.lazy(() => import(/*webpackChunkName: "admin" */ './../Views/Admin/Categories'))
const Items = React.lazy(() => import(/*webpackChunkName: "admin" */ './../Views/Admin/Items'))
const Quotes = React.lazy(() => import(/*webpackChunkName: "admin" */ './../Views/Admin/Quotes'))

const CompanyUsers = React.lazy(() => import(/*webpackChunkName: "customer" */ './../Views/Customer/Users'))
const CompanyQuotes = React.lazy(() => import(/*webpackChunkName: "customer" */ './../Views/Customer/Quotes'))


export const Routes = (props) => {    
    const auth = useSelector(state => state.auth);
    let {user} =  auth
    
    return (
        <Fragment>
            <Suspense fallback={<Spinner />}>
                <Router>
                    <Switch>
                        
                        <RouteLayout exact path="/login" layout={AuthLayout} component={Login} />
                        <RouteLayout exact path="/signup" layout={AuthLayout} component={Signup} />
                        <RouteLayout exact path="/forgot-password" layout={AuthLayout} component={ResetPassword} />
                        {
                            (!isEmpty(user) && user.role === 'ADMIN') &&
                                <Switch>
                                    <RouteLayout exact path="/" layout={AdminLayout} component={AdminDashboard} />    
                                    <RouteLayout exact path="/companies" activeClassName="active" layout={AdminLayout} component={Companies} />    
                                    <RouteLayout exact path="/users" activeClassName="active" layout={AdminLayout} component={Users} />    
                                    <RouteLayout exact path="/categories" activeClassName="active" layout={AdminLayout} component={Categories} />    
                                    <RouteLayout exact path="/items" activeClassName="active" layout={AdminLayout} component={Items} />    
                                    <RouteLayout exact path="/quotes" activeClassName="active" layout={AdminLayout} component={Quotes} />    
                                    <Route component={Page404} />
                                </Switch>
                        }
                        {
                            (!isEmpty(user) && user.role === 'CUSTOMER') &&
                                <Switch>
                                    <RouteLayout exact path="/" layout={CustomerLayout} component={CustomerDashboard} />
                                    <RouteLayout exact path="/users" activeClassName="active" layout={CustomerLayout} component={CompanyUsers} /> 
                                    <RouteLayout exact path="/quotes" activeClassName="active" layout={CustomerLayout} component={CompanyQuotes} /> 
                                    <Route component={Page404} />
                                </Switch>
                        }
                        
                        <RouteLayout exact path="/" layout={UserLayout} component={UserDashboard} />
                        <Route component={Page404} />
                    </Switch>
                </Router>
            </Suspense>
        </Fragment>

    )
}