import {QuoteConstants as Constants} from './../Actions/Types'

//let user = localStorage.getItem('user');

const initialState = {
    quotes: []
};

export function QuoteReducer(state = initialState, action) {
    switch (action.type) {        
        case Constants.GET_ALL:            
            let items_array=[]
            for(let key in action.payload)
            {
                action.payload[key].id=key
                items_array.push(action.payload[key])
            }
            return {
                ...state,
                quotes: items_array,
            }   
            
        case Constants.ADD:
            return {
                ...state,
                quotes: [...state.quotes, action.payload]
            }
            
        case Constants.FAILED:
            
            return {
                ...state,
            } 
       
        default:
            return state
            

    }
}