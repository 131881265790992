import {CompanyConstants as Constants} from './Types'
import {Firebase} from './../../Config/firebase'
import 'firebase/database';
import "firebase/auth";
import moment from 'moment'
export const database = Firebase.database();
export const auth = Firebase.auth();

export const CompanyActions = {
    get_all,
    add,
    get_users,
    update_user,
    add_user,
    update_company,
};


function get_all() {
    const reference = database.ref('users');    
    return dispatch => {
        return new Promise((resolve, reject) => {
            reference.orderByChild('role').equalTo("CUSTOMER").on("value", function(snapshot) {
                console.log("Snapshot", snapshot.val())
                if (snapshot.val()) {
                    let payload = snapshot.val();
                    dispatch(success(payload))
                    resolve(payload)
                } 
                else
                {
                    dispatch(success([]))
                    resolve([])
                }
            }, function (error) {
                    reject(error)
                    dispatch(failure('error'))                
            });             

        });
    }
    function success(payload) { return { type: Constants.GET_ALL, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function get_users(company_id) {
    const reference = database.ref('users');    
    return dispatch => {
        return new Promise((resolve, reject) => {
            reference.orderByChild('company_id').equalTo(company_id).once('value')
                .then((snapshot) => {
                    if (snapshot.val()) {
                        console.log("Here 4");
                        dispatch(success(snapshot.val()))
                        resolve(snapshot.val())
                    } 
                    else
                    {
                        dispatch(success([]))
                        resolve([])
                    }                   
                })
                .catch(error => {
                    reject(error)
                    dispatch(failure('error'))                
                });

        });
    }
    function success(payload) { return { type: Constants.GET_USERS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function add(form_data){
    const reference = database.ref('users');
    let {email, password} = form_data
    let user = { 
        name: form_data.name, 
        email: form_data.email,
        created: moment().format('YYYY-MM-DD'),
        address: form_data.address || null,
        company_id: null,
        phone: form_data.phone || null,
        vesselname: form_data.company || null,
        role: "CUSTOMER",
        show_prices: "none"
    }
    return dispatch => {
        return new Promise((resolve, reject) => {
            auth.createUserWithEmailAndPassword(email, password).then(result => {
                reference.child(result.user.uid).set(user).then(save => {                    
                    let user_data = {...user, id: result.user.uid};
                    dispatch(success(user_data))
                    resolve(user_data)
                }).catch(err => {                    
                    dispatch(failure())
                    reject(err)
                })
            }).catch(err => {                                
                dispatch(failure())
                reject(err)
            })

        });
    }
    function success(payload) { return { type: Constants.ADD, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }    
}

function update_user(user){
    let new_user = {
        name: user.name, 
        email: user.email,
        created: user.created,
        address: user.address ||  null,
        company_id: user.company_id || null,
        vesselname: user.vesselname || null,
        role: user.role,
        show_prices: user.show_prices || "none"
    }
    const reference = database.ref('users');    
    return dispatch => {
        return new Promise((resolve, reject) => {            
            reference.child(user.id).set(new_user).then(save => {                
                dispatch(success(user))
                resolve(user)
            }).catch(err => {                    
                dispatch(failure())
                reject(err)
            })   
        })
    }
    function success(payload) { return { type: Constants.UPDATE_USER, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }    
}

function add_user(form_data){
    const reference = database.ref('users');
    let {email, password} = form_data
    let user = { 
        name: form_data.name, 
        email: form_data.email,
        created: moment().format('YYYY-MM-DD'),
        address: form_data.address || null,
        company_id: form_data.company_id || null,
        company: form_data.company || null,
        vesselname: form_data.vesselname || null,
        phone: form_data.phone || null,
        role: "USER",
        show_prices: "none"
    }
    return dispatch => {
        return new Promise((resolve, reject) => {
            auth.createUserWithEmailAndPassword(email, password).then(result => {
                reference.child(result.user.uid).set(user).then(save => {                    
                    let user_data = {...user, id: result.user.uid};
                    dispatch(success(user_data))
                    resolve(user_data)
                }).catch(err => {                    
                    dispatch(failure())
                    reject(err)
                })
            }).catch(err => {                                
                dispatch(failure())
                reject(err)
            })

        });
    }
    function success(payload) { return { type: Constants.ADD_USER, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }    
}

function update_company(user){
    let new_user = {
        name: user.name, 
        email: user.email,
        created: user.created,
        address: user.address || null,
        company_id: user.company_id || null,
        vesselname: user.vesselname || null,
        role: user.role,
        discount: user.discount || 0,
        show_prices: user.show_prices || "none"
    }
    const reference = database.ref('users');
    return dispatch => {
        return new Promise((resolve, reject) => {            
            reference.child(user.id).set(new_user).then(save => {
                dispatch(success(user))
                update_connected(user.id, new_user)
            })
            .then((data) => {                
                resolve(data)
            })
            .catch(err => {                    
                dispatch(failure())
                reject(err)
            })   
        })
    }
    function success(payload) { return { type: Constants.UPDATE_COMPANY, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }    
}

async function update_connected(id, data)
{
    return new Promise((resolve, reject) => { 
        const reference = database.ref('users');
        reference.orderByChild('company_id').equalTo(id).once("value", function(snapshot) {
            console.log("Snapshot", snapshot.val())
            if (snapshot.val()) {
                let users = snapshot.val();
                for(let key in users)
                {
                    database.ref(`users/${key}/company`).set(data)
                        .then(save => {
                            resolve(data)
                        })
                        .catch(err => {                                            
                            reject(err)
                        })                       
                }            
            } 
            else
            {                
                resolve([])
            }
        }, function (error) {
                reject(error)                
        });                    
    })
}