import React, {useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import {Link} from 'react-router-dom'
import {CartActions} from './../../Store/Actions'
import {isEmpty} from './../../Helpers'
import {Row, Button, Col, InputNumber, Alert, Input, notification} from 'antd';
import {CloseCircleFilled} from '@ant-design/icons'


const  CustomItems = (props) => {

    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState('')
    const [qty, setQty] = useState(1)
    const {custom} = useSelector(state => state.cart);        

    const dispatch = useDispatch();

    function removeItem(index)
    {
        dispatch(CartActions.remove_custom(index))
    }

    function addNewItem()
    {
        if(!isEmpty(description) || description.trim() !== "")
        {
            
            let product = {description, qty}
            dispatch(CartActions.add_custom(product))
            setDescription(null)
            setQty(1)
        }
        else
        {
            notification.error({
                message: 'Item Error',
                description: "Please enter item description",
                duration: 5,
                className: 'notification-error'
            });   
        }
    }

    function updateQty(item, qty, index)
    {
        setLoading(index)
        if(qty > 0)
        {
            item['qty'] = parseInt(qty)
            dispatch(CartActions.update_custom(item, index))
                .then((response) => {
                    setLoading(null)                    
                })
        }
        else
        {
            dispatch(CartActions.remove_custom(index))
            .then((response) => {
                setLoading(null)
            })
        }
    }

    function removeItem(index)
    {
        dispatch(CartActions.remove_custom(index))
    }

    return (
        <>
            <div className="cart-items">
                {
                    (custom && custom.length > 0) ?
                    <>
                    {
                        custom.map((item, i) =>
                        <div className="cart-item" key={i}>
                            <Row gutter={24}>
                                <Col span={1}>
                                    <Link to="#" className="remove-link" onClick={() => removeItem(i)}><CloseCircleFilled /></Link>
                                </Col>
                                <Col span={16}>
                                    <h4>{item.description}</h4>
                                </Col>
                                <Col span={6}>
                                    <InputNumber disabled={loading === i} style={{ textAlign: 'center', width: '80px' }}  onChange={(val) => updateQty(item, val, i)} value={parseInt(item.qty)} />
                                </Col>
                                
                            </Row>
                        </div>
                        )
                    }
                    </>
                    :
                    <>
                        <Alert type="warning" message="Your shopping cart is empty, please add custom request for  a quotation" description="" />
                    </>                                          
                }
                <div className="custom-item-form">
                    <Row gutter={12}>
                        <Col span={15}>
                            <Input style={{ width: '100%' }} value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Item Description/title" />
                        </Col>
                        <Col span={5}>
                            <InputNumber onChange={(val) => setQty(val) } style={{ textAlign: 'center', width: '100%' }} min={1} max={10000}  value={parseInt(qty)} />
                        </Col>
                        <Col span={4}>
                            <Button type="primary" onClick={addNewItem}>Add</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default CustomItems