export const USER_LOGOUT = 'USER_LOGOUT'

export const AuthConstants = {
    LOGIN: 'AUTH_LOGIN',
    LOGOUT: 'AUTH_LOGOUT',
    FAILED: 'AUTH_FAILED',
    AUTH: 'CHECK_USER_AUTH',
    UPDATE: 'UPDATE_PROFILE',
}

export const CompanyConstants = {
    ADD: 'ADD_COMPANY',
    FAILED: 'COMPANY_FAILED',
    REMOVE: 'REMOVE_COMPANY',
    GET_USERS: 'GET_COMPANY_USERS',
    GET_ALL: 'GET_ALL_COMPANIES',
    UPDATE: 'UPDATE_PROFILE',
    UPDATE_USER: 'UPDATE_COMPANY_USER',
    ADD_USER: 'ADD_COMPANY_USER',
    UPDATE_COMPANY: 'UPDATE_COMPANY_PROFILE'
}

export const UserConstants = {
    ADD: 'ADD_USER',
    FAILED: 'USER_FAILED',
    REMOVE: 'REMOVE_USER',
    GET_ALL: 'GET_ALL_USER',
    UPDATE: 'UPDATE_USER',
}

export const CategoryConstants = {
    ADD: 'ADD_CATEGORY',
    FAILED: 'CATEGORY_FAILED',
    REMOVE: 'REMOVE_CATEGORY',
    GET_ALL: 'GET_ALL_CATEGORIES',
    UPDATE: 'UPDATE_CATEGORY',
}

export const ItemConstants = {
    ADD: 'ADD_ITEM',
    FAILED: 'ITEM_FAILED',
    REMOVE: 'REMOVE_ITEM',
    GET_ALL: 'GET_ALL_ITEMS',
    UPDATE: 'UPDATE_ITEM',
}

export const CartConstants = {
    ADD: 'ADD_TO_CART',
    REMOVE: 'REMOVE_FROM_CART',
    GET: 'GET_CART_ITEMS',
    UPDATE: 'UPDATE_CART',
    FAILED: 'CART_FAILED',
    ADD_CUSTOM: 'ADD_CUSTOM_ITEM',
    REMOVE_CUSTOM: 'REMOVE_CUSTOM_ITEM',
    UPDATE_CUSTOM: 'UPDATE_CUSTOM_ITEM',
    EMPTY_CART: 'EMPTY_CART'
}

export const QuoteConstants = {
    ADD: 'ADD_QUOTE',
    FAILED: 'QUOTE_FAILED',
    REMOVE: 'REMOVE_QUOTE',
    GET_ALL: 'GET_ALL_QUOTES',
    UPDATE: 'UPDATE_QUOTE',
}