import React, {useState, useRef } from 'react'
import { withRouter } from 'react-router-dom';

const ScrollToTopFunc = (props)  => 
{
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    return props.children    
}
export const ScrollToTop = withRouter(ScrollToTopFunc);

export const Spinner = () => {
    return (
        <div className="full-loader">
            <i className="fa fa-spin fa-spinner" />
        </div>
        );
}

export const Page404 = () => {
    return (
        <div className="full-page-wrapper">
            <div>
                <h1>404</h1>
                <h2>Page Not Found</h2>
            </div>
        </div>
    );
  };
